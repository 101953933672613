import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Page } from '../styles/page'
import { options } from '../utils/pages'

const Faq = () => {
  const intl = useIntl()
  const [text, setText] = useState({})

  useEffect(() => {
    async function fetchPageText() {
      const response = await fetch(
        `https://cdn.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.CONTENTFUL_ACCESS_TOKEN}&content_type=page&fields.name=faq`,
      )
      const { items } = await response.json()
      if (items && items.length) setText(items[0])
    }
    fetchPageText().then(r => r)
  }, [])

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'faq' })} />
      {text.fields && (
        <Page>
          {documentToReactComponents(text.fields[intl.locale], options)}
        </Page>
      )}
    </Layout>
  )
}

export default Faq
