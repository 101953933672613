import {graphql, useStaticQuery} from 'gatsby'
import {useIntl} from 'gatsby-plugin-intl'
import React from 'react'
import Helmet from 'react-helmet'
import favicon16 from '../images/logo/favicon-16x16.png'
import favicon32 from '../images/logo/favicon-32x32.png'

const keywords = [
    `Orthodox`,
    `Amsterdam`,
    `Nicolaas`,
    `Nicholas`,
    `Myra`,
    `Tichelkerk`,
    `Православие`,
    `Амстердам`,
]

const SEO = ({title, description}) => {
    const intl = useIntl()
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
    )

    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{lang: intl.locale}}
            title={title}
            titleTemplate={`%s | ${intl.formatMessage({id: 'title'})} `}>
            <meta name='description' content={metaDescription}/>
            <meta name='keywords' content={keywords.join(`, `)}/>
            <meta property='og:title' content={title}/>
            <meta property='og:description' content={metaDescription}/>
            <meta property='og:type' content='website'/>
            <link rel='icon' type='image/png' sizes='32x32' href={favicon32}/>
            <link rel='icon' type='image/png' sizes='16x16' href={favicon16}/>
        </Helmet>
    )
}

export default SEO
