import styled from 'styled-components'

const Page = styled.section`
  padding: 1rem 6rem;
  background: ${props => props.theme.colors.creamCloud};
  position: relative;
  padding-block-end: 4rem;
  @media all and (max-width: 960px) {
    padding: 1rem 3rem;
  }
  @media all and (max-width: 767px) {
    padding: 1rem;
  }
`

export { Page }
